<script>
export default {
  beforeRouteEnter(to, from, next) {
    let query = to.query;
    console.log("query", query);
    if (query.productId && query.md5) {
      next(`/cad?md5=${query.md5}&productId=${query.productId}`);
    } else if (query.md5) {
      next(`/cad?md5=${query.md5}`);
    } else if (query.productId) {
      next(`/cad?productId=${query.productId}`);
    } else next("/cad");
  },
  beforeCreate() {
    const { params, query } = this.$route;
    const { path } = params;
    this.$router.replace({ path: "/" + path, query });
  },
  render: function(h) {
    return h(); // avoid warning message
  }
};
</script>
